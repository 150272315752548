<template>
  <div>
    <fp-panel-card :title="title">
      <template #content>
        <b-button @click="getRss"><b-icon-arrow-repeat /></b-button>
        <div style="max-height: 500px; overflow: auto; margin: 20px">
          <div style="text-align: center" v-if="!items || !items.length">
            <b-spinner />
          </div>
          <div v-else>
            <div
              style="
                margin: 20px 0px;
                background: white;
                padding: 10px;
                border-radius: 10px;
              "
              v-for="(item, index) of items"
              :key="'eeszt-rss-article-' + index"
            >
              <h1 style="font-size: 20px">
                <a style="color: black" target="_blank" :href="item.link"
                  ><b>{{ item.title }}</b></a
                >
              </h1>
              <p>
                {{ $convertDateToString(item.pubDate, "YYYY-MM-DD HH:mm") }}
              </p>
              <p v-html="item.content"></p>
              <p>rss.office.eeszt.gov.hu</p>
            </div>
          </div>
        </div>
      </template>
    </fp-panel-card>
  </div>
</template>
<script>
export default {
  name: "EesztRssReader",
  props: {
    title: String,
    url: String,
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    async getRss() {
      this.items = null;
      const res = await fetch(`https://api.allorigins.win/get?url=${this.url}`);
      const { contents } = await res.json();
      const feed = new window.DOMParser().parseFromString(contents, "text/xml");
      console.log(feed);
      const items = feed.querySelectorAll("item");
      this.items = [...items].map((el) => ({
        link: el.querySelector("link").innerHTML,
        title: el.querySelector("title").innerHTML,
        pubDate: el.getElementsByTagNameNS(
          "http://purl.org/dc/elements/1.1/",
          "date"
        )[0].innerHTML,
        content: this.decodeHTMLEntities(
          el.getElementsByTagNameNS(
            "http://purl.org/rss/1.0/modules/content/",
            "encoded"
          )[0]
        ),
      }));
      console.log(this.items);
    },
    decodeHTMLEntities(element) {
      var str = element.innerHTML;
      if (str && typeof str === "string") {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = "";
      }

      return str;
    },
  },
  async mounted() {
    await this.getRss();
  },
};
</script>
